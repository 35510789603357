.add button {
    border-radius: 3px;
    padding: 5px 25px;
    background-color: #ffc845;
    color: #003b49;
    border: none;
  }

.add .col button {
  min-width: 100%;
}

.add {
  max-width: 800px;
  margin: 100px auto;
}

.form-select {
  color:#636c72;
}

.add a {
  color: #ffc845;
}

.add button {
  border-radius: 3px;
  padding: 1% 4%;
  background-color: #ffc845;
  color: #003b49 !important;
  border: none;
  font-weight: 500;
  margin-bottom: 30px;
  font-weight: 600;
  margin-right: 20px;
}

.bulk button {
  border-radius: 3px;
  padding: 5px 25px;
  margin-left: 20px;
  background-color: #ffc845;
  color: #003b49;
  border: none;
}