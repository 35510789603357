.pro-sidebar {
  min-height: 80vh;
}

.pro-sidebar-inner {
  background-color: #003b49 !important;
}

.dashboard .pro-inner-item {
  cursor: inherit !important;
  font-weight: 700;
  font-size: 1.2em;
  color: #ffc845 !important;
}

li {
  font-size: 1.1em;
}

.dashboard a {
  color: #ffc845 !important;
}

.dashboard {
  overflow: hidden;
  display: flex;
}

.dashboard button {
  border-radius: 3px;
  padding: 5px 25px;
  background-color: #ffc845;
  color: #003b49;
  border: none;
  margin: 0 0 10px 20px;
  font-weight: 700;
}

.dashboard .select {
  border-radius: 3px;
  padding: 5px 15px;
  border: none;
  margin: 0 0 10px 20px;
  width: 180px;
}

.edit-film button {
  border-radius: 3px;
  padding: 5px 25px;
  background-color: #ffc845;
  color: #003b49;
  border: none;
}

.edit-film a {
  color: #ffc845;
  font-size: 1.1em;
  font-weight: 600;
}

.edit-film p {
  margin-bottom: 3px;
}