.profile button {
    border-radius: 3px;
    padding: 5px 25px;
    background-color: #ffc845;
    color: #003b49;
    border: none;
    margin: 0 15px 15px 0;
    font-weight: 700;
  }


  @media screen and (max-width: 1000px){
    .desktop-only {
      display: none;
    }
  }