@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;700);
body {
  margin: 0;
  padding: 0;
  font-family: "futura-pt", "Montserrat", "Helvetica Neue", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #003b49 !important;
  color: white !important;
}

body p {
  font-size: 1.2em;
}

body tr {
  font-size: 1.1em;
  font-weight: 500;
}
.middle-body {
    margin: 100px 15%;
    max-width: 70% !important;
    min-height: 60vh;
}

button {
    font-weight: 700;
}

.input-field {
    margin-bottom: 25px;
    font-size: 1.1em;
    font-weight: 600;
}

.select {
    font-size: 1.1em;
    font-weight: 600;
}

.form-label {
    font-size: 1.1em;
    font-weight: 600;
}

.invalid {
    border-width: 2px;
    border-color: red;
}

.red-text {
    color: red;
}

.react-tag-input {
    margin-bottom: 25px !important;
}

button {
    margin-left: 0px;
}

.btn {
    padding-left: 0;
}
a {
    color: #ffc845;
}

.auth button {
    border-radius: 3px;
    padding: 2% 1%;
    background-color: #ffc845;
    color: #003b49;
    border: none;
    font-weight: 700;
  }
.content {
  position: absolute;
  top: 15%;
  left: 0;
  padding: 40px;
}

.table {
  padding: 10px 20px 0 20px;
  margin-bottom: 5px;
}

img {
  height: 10px;
}

.table .film-data {
  margin: 3%;
}

.film-data p {
  margin-bottom: 0px;
  padding: 0.1rem;
}

.modal-buttons button {
  border-radius: 3px;
  padding: 5px 25px;
  background-color: #666;
  color: #fff !important;
  border: none;
}

.film-data p {
  font-size: 1.1em;
}

.table button {
  border-radius: 3px;
  padding: 5px 10px;
  margin: 0px 5px 20px 5px;
  background-color: #ffc845;
  color: #003b49;
  border: none;
  font-weight: 500;
  min-height: 20px;
}

@media screen and (max-width: 999px){
  .desktop-only {
    display: none;
  }
}

@media screen and (min-width: 1000px){
  .mobile-only {
    display: none;
  }
}
.pro-sidebar {
  min-height: 80vh;
}

.pro-sidebar-inner {
  background-color: #003b49 !important;
}

.dashboard .pro-inner-item {
  cursor: inherit !important;
  font-weight: 700;
  font-size: 1.2em;
  color: #ffc845 !important;
}

li {
  font-size: 1.1em;
}

.dashboard a {
  color: #ffc845 !important;
}

.dashboard {
  overflow: hidden;
  display: flex;
}

.dashboard button {
  border-radius: 3px;
  padding: 5px 25px;
  background-color: #ffc845;
  color: #003b49;
  border: none;
  margin: 0 0 10px 20px;
  font-weight: 700;
}

.dashboard .select {
  border-radius: 3px;
  padding: 5px 15px;
  border: none;
  margin: 0 0 10px 20px;
  width: 180px;
}

.edit-film button {
  border-radius: 3px;
  padding: 5px 25px;
  background-color: #ffc845;
  color: #003b49;
  border: none;
}

.edit-film a {
  color: #ffc845;
  font-size: 1.1em;
  font-weight: 600;
}

.edit-film p {
  margin-bottom: 3px;
}
.collections {
  height: 100%;
  display: flex;
  position: relative;
  overflow: hidden;
}

.collections .button-blue {
  margin: 0;
  text-align: center;
  border-radius: 3px;
  border: none;
  background-color: #ffc845;
  color: #003b49;
  font-weight: 700;
  width: 100%;
  height: 100%;
}

.pro-sidebar {
  color: #ffc845 !important;
}

@media screen and (max-width: 999px){
  .pro-sidebar {
    display: none;
  }
}

.pro-sidebar-inner {
  background-color: #003b49 !important;
}

.collections .pro-inner-item {
  cursor: inherit !important;
}

.collections .pro-inner-item:hover {
  color: #ffc845 !important;
}

.collections a {
  width: 35% !important;
  margin: 0;
  padding: 0;
  height: 50px;
}

.pro-sidebar p {
  margin: 10px 0 5px 0;
  font-weight: 500;
}

.filter-badge {
  color: #003b49;
  background-color: #ffc845 !important;
  padding: 0.6em 0.7em;
  font-weight: 700;
  font-size: 0.9em;
  cursor: pointer;
}

.selected {
  background-color: #d0a132 !important;
}

@media screen and (min-width: 1000px){
  .search {
    max-width: 60%;
  }
}

@media screen and (max-width: 1000px){
  .desktop-only {
    display: none;
  }
}
.home {
    min-height: 70vh;
}

.home .mobile a {
    color: #003b49 !important;
    font-weight: 700;
    background-color: #ffc845 !important;
    border-radius: 3px;
    padding: 2%;
    margin: 8px auto;
    text-align: center;
    min-width: 280px;
    text-decoration: none;
}

@media screen and (min-width: 1000px){
    .mobile {
      display: none;
    }
}
.add button {
    border-radius: 3px;
    padding: 5px 25px;
    background-color: #ffc845;
    color: #003b49;
    border: none;
  }

.add .col button {
  min-width: 100%;
}

.add {
  max-width: 800px;
  margin: 100px auto;
}

.form-select {
  color:#636c72;
}

.add a {
  color: #ffc845;
}

.add button {
  border-radius: 3px;
  padding: 1% 4%;
  background-color: #ffc845;
  color: #003b49 !important;
  border: none;
  font-weight: 500;
  margin-bottom: 30px;
  font-weight: 600;
  margin-right: 20px;
}

.bulk button {
  border-radius: 3px;
  padding: 5px 25px;
  margin-left: 20px;
  background-color: #ffc845;
  color: #003b49;
  border: none;
}
.contact p {
    padding: 0px;
    margin-bottom: 0px;
}

.contact h6 {
    font-size: 1.2em;
}

.faqs {
    max-width: 900px !important;
}

.faqs p {
    margin-top: 7px;
}

.faqs a {
    color: #ffc845;
}

.faqs button {
    border-radius: 3px;
    padding: 1% 4%;
    background-color: #ffc845;
    color: #003b49 !important;
    border: none;
    font-weight: 500;
    margin-bottom: 30px;
    font-weight: 600;
    margin-right: 20px;
}

.static a {
    color: #ffc845;
}

.static {
    max-width: 900px !important;
}

.static h1 {
    margin-bottom: 20px;
}

.static button {
    border-radius: 3px;
    padding: 1% 4%;
    margin: 10px 12px 30px 0;
    background-color: #ffc845;
    color: #003b49 !important;
    border: none;
    font-weight: 600;
}

.how-to button {
    border-radius: 3px;
    padding: 4%;
    margin: 10px 12px 0 0;
    background-color: #ffc845;
    color: #003b49 !important;
    border: none;
    font-weight: 500;
    min-height: 65px;
}

.how-to .resources {
    padding: 15%;
}

.how-to li p {
    margin-bottom: 2px;
    font-size: 1.1em;
}

.how-to .little {
    border-radius: 3px;
    padding: 5px 10px;
    margin: 3px 10px 12px 10px;
    background-color: #ffc845;
    color: #003b49 !important;
    border: none;
    font-weight: 500;
    min-height: 20px;
}

.how-to h2 {
    margin-bottom: 15px;
    margin-right: 20px;
}

.how-to h4 {
    margin-bottom: 15px;
}
.navbar a {
    color: #003b49 !important;
    font-weight: 700;
}
.navbar .button-yellow {
  margin: auto;
  text-align: center;
}

.navbar .button-yellow a {
    border-radius: 3px;
    padding: 2%;
    margin: 8px 12px;
    background-color: #003b49;
    color: #ffc845 !important;
  }

.navbar-brand img {
  height: 75px;
  padding-top: 20px;
}

@media screen and (max-width: 999px){
  .desktop {
    display: none !important;
  }
  
  .footer-links {
    max-width: 50%;
  }
}

@media screen and (max-width: 1200px){

  .image-row {
    display: none;
  }

}

@media screen and (min-width: 1000px){
  .mobile {
    display: none !important;
  }

  .navbar .button-yellow a {
    min-width: 50%;
  }
  .footer-links {
    max-width: 15%;
  }
}

@media screen and (max-width: 1200px) {
  .navbar-brand img {
    height: 40px;
    padding-top: 0px;
    padding-right: 5px;
  }
  .navbar-brand h1 {
    display: none;
  }

  .nav-link {
    padding: 5px;
  }
}



footer {
  background-color: #ffc845;
  color: #003b49 !important;
  z-index: 1000;
}

footer h6 {
  font-weight: 700 !important;
  margin-bottom: 4px;
  text-decoration: none;
}

.footer a {
  color: #003b49 !important;
  text-decoration: none;
}

.profile button {
    border-radius: 3px;
    padding: 5px 25px;
    background-color: #ffc845;
    color: #003b49;
    border: none;
    margin: 0 15px 15px 0;
    font-weight: 700;
  }


  @media screen and (max-width: 1000px){
    .desktop-only {
      display: none;
    }
  }
