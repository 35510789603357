.contact p {
    padding: 0px;
    margin-bottom: 0px;
}

.contact h6 {
    font-size: 1.2em;
}

.faqs {
    max-width: 900px !important;
}

.faqs p {
    margin-top: 7px;
}

.faqs a {
    color: #ffc845;
}

.faqs button {
    border-radius: 3px;
    padding: 1% 4%;
    background-color: #ffc845;
    color: #003b49 !important;
    border: none;
    font-weight: 500;
    margin-bottom: 30px;
    font-weight: 600;
    margin-right: 20px;
}

.static a {
    color: #ffc845;
}

.static {
    max-width: 900px !important;
}

.static h1 {
    margin-bottom: 20px;
}

.static button {
    border-radius: 3px;
    padding: 1% 4%;
    margin: 10px 12px 30px 0;
    background-color: #ffc845;
    color: #003b49 !important;
    border: none;
    font-weight: 600;
}

.how-to button {
    border-radius: 3px;
    padding: 4%;
    margin: 10px 12px 0 0;
    background-color: #ffc845;
    color: #003b49 !important;
    border: none;
    font-weight: 500;
    min-height: 65px;
}

.how-to .resources {
    padding: 15%;
}

.how-to li p {
    margin-bottom: 2px;
    font-size: 1.1em;
}

.how-to .little {
    border-radius: 3px;
    padding: 5px 10px;
    margin: 3px 10px 12px 10px;
    background-color: #ffc845;
    color: #003b49 !important;
    border: none;
    font-weight: 500;
    min-height: 20px;
}

.how-to h2 {
    margin-bottom: 15px;
    margin-right: 20px;
}

.how-to h4 {
    margin-bottom: 15px;
}