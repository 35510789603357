.middle-body {
    margin: 100px 15%;
    max-width: 70% !important;
    min-height: 60vh;
}

button {
    font-weight: 700;
}

.input-field {
    margin-bottom: 25px;
    font-size: 1.1em;
    font-weight: 600;
}

.select {
    font-size: 1.1em;
    font-weight: 600;
}

.form-label {
    font-size: 1.1em;
    font-weight: 600;
}

.invalid {
    border-width: 2px;
    border-color: red;
}

.red-text {
    color: red;
}

.react-tag-input {
    margin-bottom: 25px !important;
}

button {
    margin-left: 0px;
}

.btn {
    padding-left: 0;
}