.collections {
  height: 100%;
  display: flex;
  position: relative;
  overflow: hidden;
}

.collections .button-blue {
  margin: 0;
  text-align: center;
  border-radius: 3px;
  border: none;
  background-color: #ffc845;
  color: #003b49;
  font-weight: 700;
  width: 100%;
  height: 100%;
}

.pro-sidebar {
  color: #ffc845 !important;
}

@media screen and (max-width: 999px){
  .pro-sidebar {
    display: none;
  }
}

.pro-sidebar-inner {
  background-color: #003b49 !important;
}

.collections .pro-inner-item {
  cursor: inherit !important;
}

.collections .pro-inner-item:hover {
  color: #ffc845 !important;
}

.collections a {
  width: 35% !important;
  margin: 0;
  padding: 0;
  height: 50px;
}

.pro-sidebar p {
  margin: 10px 0 5px 0;
  font-weight: 500;
}

.filter-badge {
  color: #003b49;
  background-color: #ffc845 !important;
  padding: 0.6em 0.7em;
  font-weight: 700;
  font-size: 0.9em;
  cursor: pointer;
}

.selected {
  background-color: #d0a132 !important;
}

@media screen and (min-width: 1000px){
  .search {
    max-width: 60%;
  }
}

@media screen and (max-width: 1000px){
  .desktop-only {
    display: none;
  }
}