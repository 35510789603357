a {
    color: #ffc845;
}

.auth button {
    border-radius: 3px;
    padding: 2% 1%;
    background-color: #ffc845;
    color: #003b49;
    border: none;
    font-weight: 700;
  }