@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;700');

body {
  margin: 0;
  padding: 0;
  font-family: "futura-pt", "Montserrat", "Helvetica Neue", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #003b49 !important;
  color: white !important;
}

body p {
  font-size: 1.2em;
}

body tr {
  font-size: 1.1em;
  font-weight: 500;
}