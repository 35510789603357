.home {
    min-height: 70vh;
}

.home .mobile a {
    color: #003b49 !important;
    font-weight: 700;
    background-color: #ffc845 !important;
    border-radius: 3px;
    padding: 2%;
    margin: 8px auto;
    text-align: center;
    min-width: 280px;
    text-decoration: none;
}

@media screen and (min-width: 1000px){
    .mobile {
      display: none;
    }
}