.content {
  position: absolute;
  top: 15%;
  left: 0;
  padding: 40px;
}

.table {
  padding: 10px 20px 0 20px;
  margin-bottom: 5px;
}

img {
  height: 10px;
}

.table .film-data {
  margin: 3%;
}

.film-data p {
  margin-bottom: 0px;
  padding: 0.1rem;
}

.modal-buttons button {
  border-radius: 3px;
  padding: 5px 25px;
  background-color: #666;
  color: #fff !important;
  border: none;
}

.film-data p {
  font-size: 1.1em;
}

.table button {
  border-radius: 3px;
  padding: 5px 10px;
  margin: 0px 5px 20px 5px;
  background-color: #ffc845;
  color: #003b49;
  border: none;
  font-weight: 500;
  min-height: 20px;
}

@media screen and (max-width: 999px){
  .desktop-only {
    display: none;
  }
}

@media screen and (min-width: 1000px){
  .mobile-only {
    display: none;
  }
}