.navbar a {
    color: #003b49 !important;
    font-weight: 700;
}
.navbar .button-yellow {
  margin: auto;
  text-align: center;
}

.navbar .button-yellow a {
    border-radius: 3px;
    padding: 2%;
    margin: 8px 12px;
    background-color: #003b49;
    color: #ffc845 !important;
  }

.navbar-brand img {
  height: 75px;
  padding-top: 20px;
}

@media screen and (max-width: 999px){
  .desktop {
    display: none !important;
  }
  
  .footer-links {
    max-width: 50%;
  }
}

@media screen and (max-width: 1200px){

  .image-row {
    display: none;
  }

}

@media screen and (min-width: 1000px){
  .mobile {
    display: none !important;
  }

  .navbar .button-yellow a {
    min-width: 50%;
  }
  .footer-links {
    max-width: 15%;
  }
}

@media screen and (max-width: 1200px) {
  .navbar-brand img {
    height: 40px;
    padding-top: 0px;
    padding-right: 5px;
  }
  .navbar-brand h1 {
    display: none;
  }

  .nav-link {
    padding: 5px;
  }
}



footer {
  background-color: #ffc845;
  color: #003b49 !important;
  z-index: 1000;
}

footer h6 {
  font-weight: 700 !important;
  margin-bottom: 4px;
  text-decoration: none;
}

.footer a {
  color: #003b49 !important;
  text-decoration: none;
}
